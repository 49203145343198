<template>
  <teleport to="body">
    <audio v-if="props.src" ref="audioRef" />
  </teleport>
</template>

<script setup>
import {ref, onMounted, defineProps, watch, onUnmounted, defineEmits, defineExpose} from 'vue'

const props = defineProps({
  src: {type: String, default: ''},
  action: {type: Boolean, default: false},
  duration: {type: Number, default: 0},
  volume: {type: Number, default: 1}
})

const emit = defineEmits(['ended', 'update:action'])

defineExpose({
  played: () => played(),
  ended: () => ended()
})

const audioRef = ref(null)
const durationInMinutes = ref(props.duration * 60)

let loopInterval
let pauseTimeout

const setLoop = () => {
  const audio = audioRef.value
  if (!audio) return

  const currentTime = audio.currentTime
  const buffer = .5

  if (audio && currentTime >= audio.duration - buffer) {
    audio.currentTime = 0
    audio.play()
  }
}

const graduallySound = () => {
  const audio = audioRef.value
  if (!audio) return

  // Gradually reduce the volume over 1 seconds
  const fadeOutDuration = 1000
  const stepInterval = 5
  const step = audio.volume * stepInterval / fadeOutDuration

  const volumeInterval = setInterval(() => {
    audio.volume = Math.max(0, audio.volume - step)
    if (audio.volume <= 0) {
      clearInterval(volumeInterval)
      ended()
      emit('ended')
    }
  }, stepInterval)
}

const preLoadAudio = () => {
  return new Promise(resolve => {
    const audio = audioRef.value

    audio.addEventListener('canplaythrough', () => {
      resolve(audio)
    })

    audio.preload = 'auto'
    audio.volume = props.volume
    audio.src = props.src

    audio.load()
  })
}

const played = async () => {
  const audio = await preLoadAudio()

  audio.currentTime = 0

  audio.play()
}

const ended = async () => {
  const audio = await preLoadAudio()
  audio.pause()

  clearTimeout(pauseTimeout)
  clearInterval(loopInterval)
}

const updateDurationInMinutes = () => {
  durationInMinutes.value = props.duration * 60
}

onMounted(() => {
  if (props.duration) {
    loopInterval = setInterval(setLoop, 100)

    pauseTimeout = setTimeout(() => {
      graduallySound()
    }, durationInMinutes.value * 1000)
  }

  document.addEventListener('visibilitychange', function () {
    const audio = audioRef.value
    if (!audio) return

    if (document.visibilityState === 'hidden') {
      if (!audio.paused) {
        ended()
        emit('update:action', false)
      }
    }
  });
})

onUnmounted(() => {
  clearInterval(loopInterval)
  clearTimeout(pauseTimeout)
})

watch(() => props.action, async (value) => {
  value === true ? await played() : await ended()
})

watch(() => props.duration, (newDuration, oldDuration) => {
  if (newDuration < oldDuration) {
    pauseTimeout = setTimeout(() => {
      graduallySound()
    }, newDuration * 60 * 1000)
  }

  updateDurationInMinutes()
})
</script>
