<template>
  <button @mousedown="debouncedHandleTap"
          @touchend="handleTouchend"
          class="normal-btn"
          :style="{
        'width': width + 'px',
        'height': height + 'px',
        'margin': margin + 'px'
      }">
    {{ label ? label : null }}
    <slot name="value" />
    <audio-sound v-model:action="btnTap" src="audio/btn_tap.mp3"/>
  </button>
</template>

<script setup>
import {defineProps, defineEmits, ref} from 'vue'
import AudioSound from "@/components/AudioSound.vue";

defineProps({
  width: {type: Number, default: 135 },
  height: {type: Number, default: 45,},
  label: {type: String, default: '' },
  margin: {type: Number, default: 0 }
})

const emit = defineEmits(['clicked'])

const btnTap = ref(false)

const debounce = (callback, time = 100, immediate = false) => {
  let interval;
  const context = this;
  return (...args) => {
    const later = () => {
      interval = null;
      if (!immediate) {
        callback.apply(context, args);
      }
    };
    const callNow = immediate && !interval;
    clearTimeout(interval);
    interval = setTimeout(later, time);
    if (callNow) {
      callback.apply(context, args);
    }
  };
};

const handleTap = () => {
  btnTap.value = true
  emit('clicked')
}

const handleTouchend = () => {
  btnTap.value = false
}

const debouncedHandleTap = debounce(handleTap, 10)

</script>

<style scoped>
.normal-btn {
  border: 0;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  margin: 18px;
  font-weight: 600;
  background-color: #aa9b78;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
</style>
