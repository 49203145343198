const topMenu = [
  {
    route: '/set-fuel',
    label: 'noseru craftをはじめる',
  },
  {
    route: '/video',
    label: '作り方を見る（動画）',
  }
]

export { topMenu }
