const slides = [
  {
    id: 1,
    target: '',
    content: 'noseru craftへようこそ</br>主な機能を説明します'
  },
  {
    id: 2,
    target: '#nav-1',
    content: `サウンドボタンをタップすると</br>サウンドの再生、選択ができます`
  },
  {
    id: 3,
    target: '#nav-2',
    content: `タイマーをセットすると</br>設定した時間で火が消えます</br>設定しない場合は20分で終了します`
  },
  {
    id: 4,
    target: '#nav-3',
    content: `終了したい時は消火を</br>タップしてください`
  },
]

export { slides }
