<template>
  <div class="set-craft bg__setcraft">
    <div class="overlay"/>

    <div class="set-craft__main">
      <div class="set-craft__wrap">
        <div class="set-craft__img">
          <img src="@/assets/images/bonfiresheet.png" alt="img_bonfiresheet" class=" img img__bonfiresheet">
          <img src="@/assets/images/img_takibidai.png" alt="takibidai" class=" img img__takibidai">
          <img src="@/assets/images/img_fuel.png" alt="fuel" class="img img__fuel">
          <img src="@/assets/images/ani_square.png" alt="square" class="img img__square">
        </div>
      </div>

      <paper-craft class="paper-craft"/>

      <div class="set-craft__footer">
        <p>焚き火台の上に<br/> 作ったペーパークラフトをセット</p>
        <base-button @clicked="onClickedBtn" :width="190" :height="50" :margin="18" label="セット完了"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import BaseButton from '@/components/BaseButton.vue'
import PaperCraft from "@/components/PaperCraft.vue";
import {useRouter} from 'vue-router';

const router = useRouter();

const onClickedBtn = () => {
  router.replace('/ignite')
}

</script>
<style lang="scss" scoped>
.set-craft {
  margin: 0 auto;
  overflow: hidden;
}

.bg__setcraft {
  background-image: url(@/assets/images/bg_kareha.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.set-craft {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    overflow: hidden;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      font-size: calc(36px / 2);
      line-height: calc(54px / 2);
      margin: 0;
      text-align: center;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .paper-craft {
    position: absolute;
    bottom: calc((100% - (100% * 3) / 4));
  }

  &__footer {
    position: absolute;
    bottom: 0;

    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    height: 300px;
    z-index: 3;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &__square {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        -webkit-animation: zoom 2s linear infinite alternate;
        -moz-animation:    zoom 2s linear infinite alternate;
        -o-animation:      zoom 2s linear infinite alternate;
        animation:         zoom 2s linear infinite alternate;
      }

      &__takibidai {
        width: calc(100% * 0.7);
        height: calc(100% * 0.7);
        position: absolute;
        z-index: 0;
      }

      &__fuel {
        width: calc(100% * 0.6);
        height: calc(100% * 0.6);
        position: absolute;
        z-index: 1;
      }

      &__craft {
        width: calc(120px / 2);
        height: auto;
        margin: 0 auto calc(12px / 2);
      }

      &__shape {
        width: calc(195px / 2);
        height: calc(23px / 2);
        margin: 0 auto calc(43px / 2);
      }

      &__arrow-down {
        width: calc(32px / 2);
        height: calc(46px / 2);
        margin: 0 auto calc(4px / 2);
      }
    }
  }

  .btn__setcraft {
    position: absolute;
    bottom: 8px;
  }
}

@-webkit-keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-moz-keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-o-keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

</style>
