const timers = [
  {
    id: 1,
    text: '3分',
    time: 3 // 3 minutes
  },
  {
    id: 2,
    text: '10分',
    time: 10 // 10 minutes
  },
  {
    id: 3,
    text: '8時間',
    time: 480 // 8 hours
  }
]

export { timers }
